import axios from 'axios';
import {
    axios_requer,
    axios_file
} from './http_axios'


const apiList = {
    api_upload: '/base/saas/v1/file/upload', //上传文件
    api_oauthAdmin: '/v1/admin/oauth/oauthAdmin', //登录
    api_pushFindFieldSort: '/v1/admin/customized/pushFindFieldSort', //保存用户查询字段排序规则
    api_getFindFieldSort: '/v1/admin/customized/getFindFieldSort', //获取用户查询字段排序规则(弃用)
    api_getGroupFindFieldSort: '/v1/admin/customized/getGroupFindFieldSort', //获取用户查询字段排序规则
    api_packageList: '/v1/admin/pack/packageList', //包裹综合查询
    api_getAdminMenus: '/v1/admin/oauth/getAdminMenus', //获取左侧菜单数组
    api_getUserExportTemplate: '/v1/admin/poi/getUserExportTemplate', //获取用户导出模板 ===>get
    api_exportTemplateDownload: '/v1/admin/poi/exportTemplateDownload', //下载用户导出模板  ==>post / json
    api_saveExportTemplate: '/v1/admin/poi/saveExportTemplate', //保存用户导出模板  ==>post
    api_templateDataExport: '/v1/admin/poi/templateDataExport', //模板数据导出  ==>post
    api_courierNumberList: '/v1/admin/pack/courierNumberList', //查询快递单号列表  ==>get
    api_packageDetails: '/v1/admin/pack/packageDetails', //包裹详情  ==>get
    api_addMenu: '/v1/devops/menu/addMenu', //新增菜单  ==>post
    api_getSubMenus: '/v1/devops/menu/getSubMenus', //新增菜单  ==>post (运维)
    api_updateMenu: '/v1/devops/menu/updateMenu', //跟新菜单  ==>post (运维)
    api_delMenus: '/v1/devops/menu/delMenus', //删除菜单
    api_login: '/v1/devops/auth/login', //登录(运维系统登录)

}

// axios_requer(path, params, method, headerType)
const Api = {
    // 公共部分
    // 上传文件
    async uploadFile(data) {
        return await axios_file(apiList.api_upload, data, 'post').then(res => res);
    },
    // 运维登录接口
    async yw_login(data) {
        return await axios_requer(apiList.api_login, data, 'post').then(res => res);
    },
    // 获取登录方式
    async getLoginMethod() {
        return await axios_requer('/v1/common/loginpage/getLoginMethod').then(res => res);
    },
    // 验证码登录
    async oauthAdminByCode(data) {
        return await axios_requer('/v1/admin/oauth/oauthAdminByCode', data, 'post').then(res => res);
    },
    // 发送短信获取验证码
    async numberSplit(data) {
        return await axios_requer('/common/v1/alisms/numberSplit', data).then(res => res);
    },
    // 校验手机号是否已申请
    async isApplyForTrial(data) {
        return await axios_requer('/v1/devops/customer/isApplyForTrial', data, 'post').then(res => res);
    },
    // 获取租户应用
    async getTenantApp(data) {
        return await axios_requer('/common/v1/tenant/getTenantApp', data).then(res => res);
    },

    // 新建菜单(运维)
    async addMeun(data) {
        return await axios_requer(apiList.api_addMenu, data, 'post','json').then(res => res);
    },
    // 获取菜单(运维)
    async getSubMenus(data) {
        return await axios_requer(apiList.api_getSubMenus, data).then(res => res);
    },
    // 更新菜单(运维)
    async updateMenu(data) {
        return await axios_requer(apiList.api_updateMenu, data, 'post','json').then(res => res);
    },
    // 删除菜单(运维)
    async delMenus(data) {
        return await axios_requer(apiList.api_delMenus, data, 'post').then(res => res);
    },
    // 登录接口
    async oauthAdmin(data) {
        return await axios_requer(apiList.api_oauthAdmin, data, 'post').then(res => res);
    },

    //获取左侧菜单数组
    async getAdminMenus(data) {
        return await axios_requer(apiList.api_getAdminMenus, data).then(res => res)
    },
    //获取权限列表
    async getPermissionList(data) {
        return await axios_requer('/v1/devops/menu/getPermissionList', data).then(res => res)
    },
    //添加菜单权限
    async addMenuPermission(data) {
        return await axios_requer('/v1/devops/menu/addMenuPermission', data, 'post', 'json').then(res => res)
    },

    //获取菜单权限详情
    async getMenusPermissionList(data) {
        return await axios_requer('/v1/devops/menu/getMenusPermissionList', data).then(res => res)
    },
    //根据菜单获取对应的租户列表
    async getMenusTenantList(data) {
        return await axios_requer('/v1/devops/menu/getMenusTenantList', data).then(res => res)
    },

}


export {
    apiList,
    Api
};